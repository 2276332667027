
.button {
  align-items: center;
  border-radius: 0.25em;
  box-shadow: var(--shadow-elevation-low);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  height: 3.1em;
  justify-content: center;
  line-height: 3.1em;
  margin: 0;
  overflow: hidden;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all 150ms ease-in;

  &:hover {
    box-shadow: none;
  }

  &.compact {
    height: 2em;
    padding: 0.5em;
  }
}

.primary {
  background-color: var(--primary-contrast-color);
}

.secondary {
  background-color: var(--secondary-contrast-color);
}

.ghost {
  background-color: transparent;
  border: 1px solid var(--main-border-color);
}
