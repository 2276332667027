
.topBar {
  align-items: center;
  background: var(--secondary-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 0.25em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4em;
  padding: 0.5em;
  width: 1200px;

  .title {
    color: var(--main-border-color);
    font-size: x-large;
    margin-left: 0.5em;
  }

  .rightPart {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .avatar {
    border: 2px solid var(--main-border-color);
    border-radius: 50%;
    height: 32px;
    margin-left: 0.5em;
    margin-right: 0.5em;
    overflow: hidden;
    width: 32px;

    img {
      border-radius: 50%;
      height: 32px;
      width: 32px;
    }
  }
}

.input {
  background: var(--offset-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 0.25em;
  color: var(--secondary-background-color);
  padding: 0.25em;
}

:global {
  ::placeholder {
    color: var(--secondary-background-color-light);
  }
}
