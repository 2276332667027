@use '../../Themes/Lib/shadows';

.panel {
  @include shadows.box-shadow(0 2px 5px 5px);

  align-self: flex-start;
  backdrop-filter: blur(3px);
  background: rgb(5 5 5 / 44%);
  border: 2px solid var(--main-border-color);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
}

.title {
  background-color: var(--secondary-background-color);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--secondary-background-color-contrast);
  font-size: x-large;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center;
  width: 100%;
}

.content {
  align-content: center;
  background-color: var(--offset-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.loginBanner {
  background-image: var(--login-banner-image);
  background-size: cover;
  height: 220px;
  width: 600px;
}
