@use '../../../../Themes/Lib/breakpoints';
@use 'sass:color';

:global {
  /* stylelint-disable-next-line selector-class-pattern */
  .Modal {
    bottom: auto;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ReactModal__Overlay {
    background-color: rgb(0 0 0 / 75%) !important;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ReactModal__Content {
    background-color: rgba(0, 0, 0, 0.8) !important;
    border: 2px solid var(--main-border-color);
    color: var(--main-font-color);
    max-height: 80%;
    overflow: scroll;
    padding: 1em;

    h1 {
      color: var(--main-border-color);
      font-size: x-large;
      margin-bottom: 1em;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 10;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.close {
  color: var(--main-border-color);
  cursor: pointer;
  font-size: x-large;
  position: absolute;
  right: 20px;
  top: 10px;
}

.infoBlocks {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;

  > div,
  > table {
    margin-bottom: 1em;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  @include breakpoints.media-breakpoint-min(xl) {
    flex-direction: column;
  }
}

.spacer {
  margin-bottom: 1em;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .coordinates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.splitPane {
  display: flex;
  flex-direction: row;

  @include breakpoints.media-breakpoint-min(xl) {
    flex-direction: column;
  }
}

.googleMapsContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 320px;
  width: 100%;

  .mapHeader {
    background: var(--secondary-background-color);
    color: var(--secondary-background-color-contrast);
    padding-left: 0.25em;
    padding-right: 0.25em;
    text-align: center;
  }
}

.notificationContainer {
  margin-bottom: 1em;
}

.historyContainer {
  .historySelected {
    background-color: var(--secondary-background-color);
  }
}
