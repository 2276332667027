
.mainContainer {
  background-image: var(--background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--main-font-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
  width: 100vw;
}
