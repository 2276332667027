.Modal {
  background-color: #3d4451;
  border-radius: 1em;
  bottom: 6em;
  left: 6em;
  overflow: hidden;
  position: absolute;
  right: 6em;
  top: 6em;
  z-index: 999;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.react-loading-skeleton {
  background-color: var(--offset-background-color-hex) !important;
  background-image: linear-gradient(90deg, var(--main-background-color-hex), var(--secondary-background-color-hex), var(--offset-background-color-hex)) !important;
  height: 0.8em;
  margin:0;
  padding:0;
}

select {
  background: var(--secondary-background-color);
  color: var(--secondary-background-contrast-color);
}