.pill {
  border-radius: 0.25em;
  display: inline-block;
  padding: 0.25em;

  &.info {
    background-color: var(--success-color);
  }

  &.warning {
    background-color: var(--warning-color);
    color: #000;
  }

  &.alert {
    background-color: var(--alert-color);
  }
}
