/*
* Implementation of this file is inspired by:
* https://github.com/twbs/bootstrap/blob/c28934cb1871d6b6bd6a866660493a1137de17c6/scss/mixins/_breakpoints.scss
*/

/*
* Map below displays the minimum width for each size, using the following logic
* xs: 0-576px
* sm: 576px-768px
* md: 768px-992px
* lg: 992px-1200px
* xl: 1200px+
*/
$breakpoints: (xs: 0, sm: 36em, md: 48em, lg: 62em, xl: 75em);

/*
* Minimum breakpoint width. Null for the smallest (first) breakpoint.
*
*     >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
*     576px
*/
@function breakpoint-min($name) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

@mixin media-breakpoint-min($name) {
  $min: breakpoint-min($name);

  @if $min {
    @media (max-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

/* Export colors to use in Typescript */
:export {
  breakpointlg: map-get($breakpoints, lg);
  breakpointmd: map-get($breakpoints, md);
  breakpointsm: map-get($breakpoints, sm);
  breakpointxl: map-get($breakpoints, xl);
  breakpointxs: map-get($breakpoints, xs);
}
