.frostedGlass {
  align-self: center;
  backdrop-filter: blur(10px);
  background: rgb(5 5 5 / 44%);
  border: 2px solid var(--main-border-color);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  width: 1200px;
}
