@use 'sass:color';
@use '../../Themes/Lib/breakpoints';

:global {
  .paginator {
    background: var(--secondary-background-color);
    display: flex;
    flex-direction: row;
    padding: 0.75em;
    transition: all 0.3s ease-in-out;

    li {
      list-style-type: none;
      text-align: center;

      &.previous {
        margin-right: 1em;
      }

      &.next {
        margin-left: 1em;
      }

      &.selected {
        a {
          background-color: var(--offset-background-color);
          border: 1px solid  var(--main-border-color);
        }
      }

      a {
        cursor: pointer;
        display: inline-block;
        min-width: 2em;
        text-align: center;
      }
    }
  }
}

.flag {
  color: var(--alert-color);
  text-align: center;
  width: 1em !important;
}

.warningFlag {
  color: var(--warning-color);
}

.table {
  align-self: flex-start;
  width: 100%;

  caption {
    background: var(--secondary-background-color);
    color: var(--secondary-background-color-contrast);
    padding-left: 0.25em;
    padding-right: 0.25em;

    @include breakpoints.media-breakpoint-min(xl) {
      text-align: left;
    }
  }

  thead,
  tfoot {
    th {
      background: var(--secondary-background-color);
      color: var(--secondary-background-color-contrast);
      padding-left: 0.25em;
      padding-right: 0.25em;
      text-align: left;
    }
  }

  tbody {
    tr {
      &:hover {
        background: var(--offset-background-color);
      }

      td {
        padding-left: 0.25em;
        padding-right: 0.25em;
        width: 50%;
      }
    }
  }
}

.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.5em;
}

.live {
  align-self: flex-end;
  cursor: pointer;

  path {
    fill: var(--success-color);
  }
}

.static {
  align-self: flex-end;
  cursor: pointer;

  path {
    fill: var(--alert-color);
  }
}

.topBar {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.25em;
  width: 1200px;
}
